<template>
    <div>
        <b-card title="Outlets">
            <delete-items-button v-if="$hasPermission($permissions.RemoveOutlet)" :selection="selection" @deleted="loadData" @reset="selection.selected_item_ids = []" />

            <b-button v-if="$hasPermission($permissions.AddOutlet)" variant="primary" @click="$router.push('/outlet/add')" class="mr-1">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add outlet</span>
            </b-button>

            <b-button v-if="$hasPermission($permissions.ExportExcel)" @click="exportToExcel" class="mr-1">Export</b-button>
            <b-button @click="$router.push({name: 'outlet-import-from-excel'})" class="mr-1">Import from Excel</b-button>

            <basic-table ref="basicTable" :columns="columns" :data="formattedData" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                <div v-if="props.column.displayType === 3 && $hasPermission($permissions.RemoveOutlet)" class="d-flex justify-content-center">
                    <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                </div>
                <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button variant="success" @click="$router.push('/outlet/' + props.row.id)" class="mr-1">
                        <feather-icon icon="EyeIcon"/>
                    </b-button>
                    <!-- IF WAITING APPROVAL OR DEACTIVATED -->
                    <b-button variant="success" v-if="$hasPermission($permissions.ApproveOutlet) && (props.row.status_raw === 0 || props.row.status_raw === 2)"
                              v-b-tooltip.hover.top="'Activate'"
                              @click="onChangeOutletStatus(props.row.id, 1)">
                        <feather-icon icon="CheckIcon"/>
                    </b-button>
                    <!-- IF ACTIVE -->
                    <b-button variant="danger" v-if="$hasPermission($permissions.DeactivateOutlet) && props.row.status_raw === 1" v-b-tooltip.hover.top="'Deactivate'"
                              @click="onChangeOutletStatus(props.row.id, 2)">
                        <feather-icon icon="XIcon"/>
                    </b-button>
                </div>
                <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-badge v-if="props.row.status_raw === 0" variant="light-primary">
                        Waiting approval
                    </b-badge>
                    <b-badge v-else-if="props.row.status_raw === 1" variant="light-success">
                        Active
                    </b-badge>
                    <b-badge v-else-if="props.row.status_raw === 2" variant="light-danger">
                        Deactivated
                    </b-badge>
                </span>
                <span v-else class="d-flex justify-content-center">
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </basic-table>
        </b-card>
    </div>
</template>
<script>

    import {BBadge, BButton, BCard, VBTooltip, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'
    import $permissions from '@/permissions'

    export default {
        components: {
            DeleteItemsButton,
            // eslint-disable-next-line vue/no-unused-components
            'b-tooltip': VBTooltip,
            BCard,
            BButton,
            BasicTable,
            BBadge,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                outlets: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 3,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Owner name',
                        displayType: 0,
                        field: 'owner_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Contact number',
                        displayType: 0,
                        field: 'contact_number',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 2,
                        field: 'status',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'TIN',
                        displayType: 0,
                        field: 'tin',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Segment',
                        displayType: 0,
                        field: 'segment',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Area',
                        displayType: 0,
                        field: 'area',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                statusMap: new Map([[0, 'Waiting approval'], [1, 'Active'], [2, 'Change needed'], [3, 'Deactivated']])
            }
        },
        methods: {
            exportToExcel() {
                window.open('/api/management/v1/export/outlets')
            },
            onChangeOutletStatus(outletID, statusID) {
                // statusID: 1 -> Active, 2 -> Deactivated

                this.dataLoaded = false
                const thisIns = this
                    
                let statusChangeURL = ''
                if (statusID === 1) statusChangeURL = 'activate'
                else if (statusID === 2) statusChangeURL = 'deactivate'
                else {
                    thisIns.$printError('There was an error updating status!')
                    return
                }

                const changeStatusPromise = this.$http.post(`/api/management/v1/outlet/${outletID}/${statusChangeURL}`)
                changeStatusPromise.then(() => {
                    
                    const idx = thisIns.outlets.findIndex(o => o.id === outletID)
                    if (idx !== -1) {
                        thisIns.outlets[idx].status = statusID
                    } 

                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(() => {
                    this.dataLoaded = true
                })
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/outlet')
                loadPromise.then(function(response) {
                    thisIns.outlets = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        computed: {
            $permissions() {
                return $permissions 
            },
            formattedData() {
                return this.outlets.map((outlet, index) => {
                    return {
                        index,
                        id: outlet.id,
                        name: outlet.name,
                        owner_name: outlet.owner_name,
                        contact_number: outlet.contact_number,
                        status_raw: outlet.status,
                        status: this.statusMap.get(outlet.status) || 'Unknown',
                        tin: outlet.tin,
                        segment: (outlet.segment.length > 0) ? outlet.segment : 'Unknown',
                        area: (outlet.area.length > 0) ? outlet.area : 'Not defined'
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'outlets'
            this.loadData()
        }
    }
</script>